<template>
  <div class="AiField">
    <div
      class="AiField-wrapper"
      :class="{
        'AiField-wrapper--disabled': disabled,
        'AiField-wrapper--error': hasError,
        'AiField-wrapper--success': success || (hasSuccess && !hasError),
        'AiField-wrapper--inverse': inverse,
      }">
      <ai-typo
        v-if="label"
        class="AiField-label"
        :class="{
          'AiField-label--inverse': inverse,
          'AiField-label--filled': modelValue,
        }"
        variant="overline-02"
        as="label"
        :for="name"
        :color="inverse ? 'neutral-400' : 'stratos-900'">
        {{ label }}
      </ai-typo>

      <div class="AiField-input">
        <div
          v-if="$slots.left"
          class="AiField-icon AiField-icon--left"
          :class="{ 'AiField-icon--clickable': iconLeftClickable }">
          <slot name="left" />
        </div>

        <slot />

        <div
          v-if="$slots.right"
          class="AiField-icon AiField-icon--right"
          :class="{ 'AiField-icon--clickable': iconRightClickable }">
          <slot name="right" />
        </div>
      </div>
    </div>

    <!-- Helpers -->
    <slot name="helper" />

    <!-- Errors -->
    <div
      v-if="hasError"
      :id="`description-` + slugify(label ? label : '')"
      class="AiField-status">
      <ai-typo
        v-for="error in errors"
        :key="`${name}-error-${error}`"
        as="span"
        variant="legal-regular"
        :color="inverse ? 'red-400' : 'red-700'"
        class="AiField-status-label">
        {{ error }}
      </ai-typo>
    </div>

    <!-- Successes -->
    <div
      v-if="hasSuccess && !hasError"
      :id="`description-` + slugify(label ? label : '')"
      class="AiField-status">
      <ai-typo
        v-for="successItem in successes"
        :key="`${name}-error-${successItem}`"
        as="span"
        variant="legal-regular"
        color="green-500"
        class="AiField-status-label">
        {{ successItem }}
      </ai-typo>
    </div>
  </div>
</template>

<script setup lang="ts">
import { slugify } from '~~/helpers';

export type FieldProps = {
  modelValue?: unknown;
  label?: string;
  name?: string;
  errors?: string[];
  successes?: string[];
  disabled?: boolean;
  inverse?: boolean;
  success?: boolean;
  iconLeftClickable?: boolean;
  iconRightClickable?: boolean;
};

type Emits = {
  (event: 'update:modelValue', value: FieldProps['modelValue']): void;
};

const props = defineProps<FieldProps>();
defineEmits<Emits>();

const hasError = computed(
  () => Array.isArray(props.errors) && props.errors.length > 0,
);

const hasSuccess = computed(
  () => Array.isArray(props.successes) && props.successes.length > 0,
);
</script>

<style scoped lang="scss">
@use '@/assets/styles/utilities/constants';
@use '@/assets/styles/utilities/colors';
@use '@/assets/styles/utilities/functions' as func;

.AiField-wrapper {
  width: 100%;
  display: inline-block;
  padding-left: constants.$inner-02;
  padding-top: constants.$inner-01;
  padding-right: constants.$inner-02;
  background-color: rgba(colors.$gold-800, 0.08);
  min-height: func.calcRem(46);
  border-bottom: 1px solid colors.$gold-800;
  transition: all 200ms;

  &--inverse {
    background-color: rgba(colors.$white, 0.08);
    border-bottom-color: colors.$gold-400;

    &:focus-within {
      border-bottom: 2px solid colors.$gold-400;
    }
  }

  &:hover:not(.AiField-wrapper--disabled) {
    background-color: rgba(colors.$gold-800, 0.12);
  }

  &:focus:not(.AiField-wrapper--disabled),
  &:focus-within:not(.AiField-wrapper--disabled) {
    border-bottom-width: 2px;
  }
}

.AiField-input {
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
}

.AiField-label {
  text-transform: uppercase;
  display: block;
  width: 100%;
  color: colors.$stratos-900;

  &--inverse {
    color: colors.$white;

    &.AiField-label--filled {
      color: colors.$neutral-300;
    }
  }

  &--filled {
    color: colors.$stratos-500;
  }
}

.AiField--disabled {
  background-color: rgba(colors.$gold-800, 0.05);
  border-bottom-color: colors.$neutral-300;

  .AiField-label {
    color: colors.$neutral-300;
  }
}

.AiField-icon {
  pointer-events: none;
  display: inline-flex;
  color: colors.$gold-800;

  &--clickable {
    cursor: pointer;
    pointer-events: auto;
  }
}

.AiField-icon--left {
  margin-right: constants.$inner-02;
}

.AiField-icon--right {
  margin-left: constants.$inner-02;
}

.AiField-wrapper--error {
  border-bottom-color: colors.$red-700;

  &.AiField-wrapper--inverse {
    border-bottom-color: colors.$red-400;
  }
}

.AiField-wrapper--success {
  border-bottom-color: colors.$green-500;
}

.AiField-status-label {
  display: block;
}
</style>
