import type { AiInputOption } from '../molecules/AiForm/interfaces';

type Options = {
  active: boolean;
  items: AiInputOption[];
  modelValue: Ref<unknown>;
};

/**
 * Override the default browser accessibility for a custom rules
 *
 * __Why ?__
 *
 * Phone prefixes are composed of a country and a numbered prefix
 *  eg: "France (+33)" or "(+33) France"
 *
 * In either case, we want the user typing on his keyboard to select the proper suggestion
 * weither he is typing the code OR the country
 */
export function useCustomSelectAccessibility(
  input: Ref<HTMLElement | undefined>,
  { active, items, modelValue }: Options,
) {
  const accessibilityBuffer = ref<string[]>([]);
  const accessibilityTimerId = ref<NodeJS.Timer>();

  const onCustomAccessibility = (event: KeyboardEvent) => {
    const characterTyped = event.key;

    if (
      [
        'tab',
        'enter',
        'arrowup',
        'arrowdown',
        'arrowright',
        'arrowleft',
      ].includes(characterTyped.toLowerCase())
    )
      return;

    event.preventDefault();

    if (!/^[a-z0-9]$/.test(characterTyped)) return;

    if (accessibilityTimerId.value) {
      clearTimeout(accessibilityTimerId.value as NodeJS.Timeout);
      accessibilityTimerId.value = undefined;
    }

    accessibilityBuffer.value.push(event.key);

    const currentSearch = accessibilityBuffer.value.join('');
    const optionMatchingPrefix = items.find(item => {
      const noPrefixLabel = item.label.toLowerCase().replace(/^\(.+\)\s/, '');

      return noPrefixLabel.startsWith(currentSearch);
    });

    const optionMatchingAll = items.find(item =>
      item.label.toLowerCase().includes(currentSearch),
    );

    if (optionMatchingPrefix) {
      modelValue.value = optionMatchingPrefix.value;
    } else if (optionMatchingAll) {
      modelValue.value = optionMatchingAll.value;
    }

    accessibilityTimerId.value = setTimeout(() => {
      accessibilityBuffer.value = [];
      accessibilityTimerId.value = undefined;
    }, 1000);
  };

  onMounted(() => {
    if (!active || !input.value) return;

    input.value.addEventListener('keydown', onCustomAccessibility);
  });

  onBeforeUnmount(() => {
    if (!input.value) return;

    input.value.removeEventListener('keydown', onCustomAccessibility);
  });
}
